import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { COLORS } from 'styles/constants/colors';
import { STYLES_CONSTANTS } from 'styles/constants/main';
import MEDIA from 'styles/media';
import { gaEvent } from 'utils/utils';

const STreatmentsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  margin-bottom: 1rem;
`;

const SListItem = styled(Link)`
  display: flex;
  width: auto;
  height: auto;
  justify-content: space-between;
  border: 2px solid ${COLORS.CREAMY};
  transition: 0.3s ease-out;
  text-decoration: none;
  color: inherit;
  align-items: center;
  padding: 1.5rem;

  ${MEDIA.max520`
    flex-wrap: wrap;
    gap: 1.75rem;
  `}

  &:hover {
    .offer-link {
      transition: 0.3s ease-out;
      color: ${STYLES_CONSTANTS.COLORS.WHITE};
    }
    color: ${STYLES_CONSTANTS.COLORS.WHITE};
    background-color: ${STYLES_CONSTANTS.COLORS.CREAMY};
  }
`;

const SText = styled.h3`
  text-align: center;
  font-size: 1.4rem;
  font-family: 'Abhaya Libre', serif;
`;

const SReadMore = styled.p`
  font-weight: bold;
  color: ${COLORS.CREAMY};
  text-transform: uppercase;
  text-align: center;

  ${MEDIA.max520`
    width:100%;
  `}
`;

export const TreatmentsList = ({ offers }) => {
  const handleClick = () => {
    gaEvent('procedure-check');
  };

  return (
    <STreatmentsListWrapper>
      {offers.map((offer) => (
        <SListItem
          key={offer.text}
          to={`/oferta/${offer.href}`}
          onClick={handleClick}
        >
          <SText>{offer.text}</SText>
          <SReadMore className="offer-link">Dowiedz się więcej</SReadMore>
        </SListItem>
      ))}
    </STreatmentsListWrapper>
  );
};
